import {api} from '../../helpers';
import {getCookieValue} from '../../helpers/getCookie';
const env = getCookieValue('env');
const environment = env === 'Production' ? 'live' : env;
const subUrl = '/users/reconciliation';

const uploadHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
function fetchProjects(params) {
  return api
    .get(`${subUrl}/projects`, {...params, env: environment?.toLowerCase()})
    .then(res => res.data);
}
function fetchProject(project_id) {
  return api.get(`${subUrl}/projects/${project_id}`).then(res => res.data);
}

function addProject(data) {
  return api
    .post(`${subUrl}/projects`, {...data, env: environment?.toLowerCase()})
    .then(res => res.data);
}

function fetchAccount(account_id) {
  return api.get(`${subUrl}/accounts/${account_id}`).then(res => res.data);
}
function fetchLinkableAccounts() {
  return api.get(`${subUrl}/accounts/linkable`).then(res => res.data);
}
function fetchAccounts(params) {
  return api.get(`${subUrl}/accounts`, {...params}).then(res => res.data);
}
function addAccount(data) {
  return api.post(`${subUrl}/accounts`, {...data}).then(res => res.data);
}
function linkAccount(data) {
  return api.post(`${subUrl}/accounts/link`, {...data}).then(res => res.data);
}

function fetchTransactions(params) {
  return api.get(`${subUrl}/transactions`, {...params}).then(res => res.data);
}
function fetchTransaction(transaction_id) {
  return api
    .get(`${subUrl}/transactions/${transaction_id}`)
    .then(res => res.data);
}
function fetchExternalTransactions(params) {
  return api
    .get(`${subUrl}/external-transactions`, {...params})
    .then(res => res.data);
}

function addTransaction(data) {
  return api.post(`${subUrl}/transactions`, {...data}).then(res => res.data);
}
function reconcileTransaction(data) {
  return api.post(`${subUrl}/reconcile`, {...data}).then(res => res.data);
}
function uploadTransaction(formData) {
  return api
    .post(`${subUrl}/transactions/upload`, formData, {
      ...uploadHeaders,
    })
    .then(res => res.data);
}
function uploadExternalTransaction(formData) {
  return api
    .post(`${subUrl}/external-transactions/upload`, formData, {
      ...uploadHeaders,
    })
    .then(res => res.data);
}

function fetchCustomers(params) {
  return api.get(`${subUrl}/customers`, {...params}).then(res => res.data);
}
function fetchCustomer(customer_id) {
  return api.get(`${subUrl}/customers/${customer_id}`).then(res => res.data);
}
function fetchAnalysisOverview(params) {
  return api
    .get(`${subUrl}/accounts/analysis/overview`, {...params})
    .then(res => res.data);
}
function fetchExternalAnalysisOverview(params) {
  return api
    .get(`${subUrl}/account/analysis/overview/external`, {...params})
    .then(res => res.data);
}
function fetchAnalysisChart(params) {
  return api
    .get(`${subUrl}/accounts/analysis/chart`, {...params})
    .then(res => res.data);
}
function fetchAnalysisReconciliationOverview(params) {
  return api
    .get(`${subUrl}/accounts/analysis/recon_overview`, {...params})
    .then(res => res.data);
}
function fetchSubscriptionInfo(company_id) {
  return api.get(`${subUrl}/company/${company_id}`).then(res => res.data);
}

function createSubscription(data) {
  return api.post(`/subscriptions/create`, {...data}).then(res => res.data);
}
function uploadEncryptedFile(formData) {
  return api
    .post(`${subUrl}/identity/statement/headers`, formData, {
      ...uploadHeaders,
    })
    .then(res => res.data);
}
export {
  addProject,
  addAccount,
  linkAccount,
  fetchProject,
  fetchAccount,
  fetchProjects,
  fetchCustomer,
  fetchAccounts,
  addTransaction,
  fetchCustomers,
  fetchTransaction,
  uploadTransaction,
  fetchTransactions,
  fetchAnalysisChart,
  createSubscription,
  uploadEncryptedFile,
  reconcileTransaction,
  fetchSubscriptionInfo,
  fetchLinkableAccounts,
  fetchAnalysisOverview,
  uploadExternalTransaction,
  fetchExternalTransactions,
  fetchExternalAnalysisOverview,
  fetchAnalysisReconciliationOverview,
};
